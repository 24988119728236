import { createApp } from 'vue'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import '@vueup/vue-quill/dist/vue-quill.bubble.css';
// import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// createApp(App).use(store).use(router).use(ElementPlus, {
//     locale: zhCn,
// }).component('QuillEditor', QuillEditor).mount('#app')

createApp(App).use(store).use(router).mount('#app')
